export const Permissions: any = {
    Administration: {
        View: 'Administration.View',
    },
    Users: {
        View: 'Users.View',
        Edit: 'Users.Edit',
        Create: 'Users.Create',
        Delete: 'Users.Delete',
        Personalization: 'Users.Personalization',
        ManageFacilitatorBiography: 'Users.ManageFacilitatorBiography',
    },
    Groups: {
        View: 'Groups.View',
        Edit: 'Groups.Edit',
        Create: 'Groups.Create',
        Delete: 'Groups.Delete',
        RestrictionBypass: 'Groups.RestrictionBypass',
        ManageSettings: 'Groups.ManageSettings',
        ManageGroupStatus: 'Groups.ManageGroupStatus',
        ManageGroupType: 'Groups.ManageGroupType',
    },
    Organizations: {
        View: 'Organizations.View',
        Edit: 'Organizations.Edit',
        Create: 'Organizations.Create',
    },
    Roles: {
        View: 'Roles.View',
        Create: 'Roles.Create',
        Edit: 'Roles.Edit',
        Delete: 'Roles.Delete',
        RestrictionBypass: 'Roles.RestrictionBypass',
    },
    Classes: {
        View: 'Classes.View',
        Create: 'Classes.Create',
        Edit: 'Classes.Edit',
        Delete: 'Classes.Delete',
        Sharing: 'Classes.Sharing',
        SharingOutsideOrganization: 'Classes.SharingOutsideOrganization',
        SharingWithinOrganization: 'Classes.SharingWithinOrganization',
        EarlyJoin: 'Classes.EarlyJoin',
    },
    Internet: {
        View: 'Internet/Games.View',
        Create: 'Internet/Games.Create',
        Edit: 'Internet/Games.Edit',
        Delete: 'Internet/Games.Delete',
        EditGeneralSettings: 'Internet/Games.EditGeneralSettings',
    },
    Homepage: {
        View: 'Homepage.View',
    },
    Calendar: {
        View: 'Calendar.View',
    },
    Videos: {
        View: 'Videos.View',
        Create: 'Videos.Create',
        Edit: 'Videos.Edit',
        Delete: 'Videos.Delete',
    },
    Chat: {
        View: 'Chat.View',
        Create: 'Chat.Create',
        Edit: 'Chat.Edit',
    },
    NewsFeeds: {
        View: 'NewsFeeds.View',
        Edit: 'NewsFeeds.Edit',
        Create: 'NewsFeeds.Create',
        Delete: 'NewsFeeds.Delete',
        EditGeneralSettings: 'NewsFeeds.EditGeneralSettings',
    },
    Announcements: {
        View: 'Announcements.View',
        Edit: 'Announcements.Edit',
        Create: 'Announcements.Create',
        Delete: 'Announcements.Delete',
        SharingWithinOrganization: 'Announcements.SharingWithinOrganization',
    },
    Promotions: {
        View: 'Promotions.View',
        Edit: 'Promotions.Edit',
        Create: 'Promotions.Create',
        Delete: 'Promotions.Delete',
        SharingWithinOrganization: 'Promotions.SharingWithinOrganization',
    },
    Reports: {
        View: 'Reports.View',
        RestrictionBypass: 'Reports.RestrictionBypass',
    },
    News: {
        View: 'News.View',
    },
    Weather: {
        View: 'Weather.View',
    },
    HelpPage: {
        View: 'HelpPage.View',
        ViewFacilitatorManual: 'HelpPage.ViewFacilitatorManual',
    },
    Invites: {
        View: 'Invites.View',
        Create: 'Invites.Create',
        Edit: 'Invites.Edit',
        Delete: 'Invites.Delete',
        RestrictionBypass: 'Invites.RestrictionBypass',
    },
    Alerts: {
        View: 'Alerts.View',
        Create: 'Alerts.Create',
        Edit: 'Alerts.Edit',
        Delete: 'Alerts.Delete',
        RestrictionBypass: 'Alerts.RestrictionBypass',
    },
    Forum: {
        View: 'Topics.View',
        Create: 'Topics.Create',
        Edit: 'Topics.Edit',
        Delete: 'Topics.Delete',
        Close: 'Topics.Close',
        SuspendUser: 'Topics.SuspendUser',
    },
} as const;
