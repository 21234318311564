<div fxLayout="row" fxLayoutAlign="center center">
    <a
        class="btn-back"
        [routerLink]="url"
        fxLayout="row"
        fxLayoutAlign="center center"
    >
        <vsc-icon class="back-icon">chevron-left</vsc-icon>
        <span class="text">{{ 'Go back' | transloco }}</span>
    </a>
    <div *ngIf="!hideRightDivider" class="divider"></div>
</div>
