import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable, map, of, switchMap } from 'rxjs';

import { UserService } from '@app/admin/admin/users/services/user.service';
import { AuthService } from '@app/core/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class TopicGuard implements CanActivate {
    constructor(
        private usersService: UserService,
        private authService: AuthService,
        private router: Router
    ) {}
    canActivate(): Observable<boolean> {
        return this.usersService
            .getUserSuspendedStatus(this.authService.userId)
            .pipe(
                switchMap((isSuspended: boolean) => {
                    if (isSuspended) {
                        this.router.navigate(['/not-found']);
                        return of(false);
                    }

                    return of(true);
                })
            );
    }
}
