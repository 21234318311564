import {
    Directive,
    Input,
    ChangeDetectorRef,
    OnDestroy,
    AfterViewInit,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { Subscription } from 'rxjs';

@Directive({ selector: '[vscConnectForm]' })
export class FormManagerDirective implements AfterViewInit, OnDestroy {
    currentValue: any;
    private subscriptions: Subscription[] = [];

    @Input('vscConnectForm')
    set data(val: any) {
        this.currentValue = val ? val : this.formGroupDirective.form.value;
        setTimeout(() => {
            if (this.currentValue) {
                this.formGroupDirective.form.patchValue(this.currentValue);
                this.formGroupDirective.form.markAsPristine();
                this.formGroupDirective.form.updateValueAndValidity();
            } else {
                this.formGroupDirective.resetForm();
            }
        });
    }
    constructor(
        private formGroupDirective: FormGroupDirective,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.subscriptions.push(
            this.formGroupDirective.statusChanges.subscribe(() => {
                this.changeDetector.detectChanges();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
    }

    public resetForm(): void {
        this.formGroupDirective.resetForm();
        this.formGroupDirective.form.markAsPristine();
    }

    public revertChanges(): void {
        if (this.currentValue) {
            this.formGroupDirective.form.patchValue(this.currentValue);
        } else {
            this.formGroupDirective.resetForm();
        }
        this.formGroupDirective.form.markAsPristine();
    }
}
