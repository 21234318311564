import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { take } from 'rxjs';

import { ClassroomService } from '@public/classroom/services/classroom.service';

@Component({
    selector: 'vsc-join-class',
    templateUrl: './join-class.component.html',
    styleUrls: ['./join-class.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinClassComponent {
    constructor(
        private router: Router,
        private classroomService: ClassroomService
    ) {}

    @Input() classSessionId: string;
    @Input() text: string = 'Join program';
    @Input() isLiveStream: boolean = false;
    @Input() isJitsiEmbedded: boolean = true;
    @Input() classId: string;

    navigateToClassroom(classSessionId: string, classId: string = null): void {
        if (this.isLiveStream && classId != null) {
            this.router.navigate([
                `/classes/${classId}/sessions/${classSessionId}`,
            ]);
            this.classroomService
                .recordClassAttendance(classSessionId)
                .pipe(take(1))
                .subscribe();
        } else if (this.isJitsiEmbedded) {
            this.router.navigate([`/jitsi-classroom/${classSessionId}/join`]);
        } else {
            this.router.navigate([`/classroom/${classSessionId}/join`]);
        }
    }
}
