import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import {
    EMPTY,
    Observable,
    Subscription,
    of,
    switchMap,
    take,
    tap,
} from 'rxjs';

import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { Constants } from '@shared/constants';
import { PagedEntities } from '@shared/models/paged-entities';

import { ForumService } from './services/forum.service';
import { TopicsQueryParams } from './services/query-params/topics.queryparams';
import { Topic } from './state/topic-state/topic';
import { TopicsQuery } from './state/topic-state/topics.query';

@Component({
    selector: 'vsc-forum',
    templateUrl: './forum.component.html',
    styleUrls: ['./forum.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForumComponent implements OnInit, OnDestroy {
    topics$: Observable<Topic[]> = this.topicsQuery.selectAll();
    isLoading$: Observable<boolean> = this.topicsQuery.selectLoading();
    userSuspendedStatus$: Observable<boolean> =
        this.topicsQuery.getUserSuspendedStatus$;

    page: number = 0;

    _item: Topic;
    isLiked: boolean = false;
    canEdit: boolean = false;
    colorsArray: Array<string> = Constants.AVATAR_COLORS;

    private subscription: Subscription;

    constructor(
        private forumService: ForumService,
        private topicsQuery: TopicsQuery,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog
    ) {}

    onScroll(): void {
        const topicsQueryParams: TopicsQueryParams = {
            search: '',
            page: this.page,
            pageSize: 10,
        };
        this.getAllTopics(topicsQueryParams);
    }

    ngOnInit(): void {
        const topicsQueryParams: TopicsQueryParams = {
            search: '',
            page: this.page,
            pageSize: 1000,
        };
        this.getAllTopics(topicsQueryParams);
    }

    createNewTopic(): void {
        this.router.navigate(['create'], {
            relativeTo: this.activatedRoute,
        });
    }

    getAllTopics(topicsQueryParams: TopicsQueryParams): void {
        this.subscription = this.forumService
            .getAllTopics(topicsQueryParams)
            .pipe(
                take(1),
                tap((response: PagedEntities<Topic>) => {
                    this.topics$ = of(response.entities as Topic[]);
                })
            )
            .subscribe(() => this.page++);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    trackByFn(topic: Topic): string {
        return topic?.id;
    }

    onRefresh(): void {
        this.ngOnInit();
    }

    togglePinTopic(item: Topic, $event: any): void {
        $event.stopPropagation();
        this.forumService.togglePinTopic(item.id, !item.isPinned).subscribe();
    }

    toggleLikeTopic(item: Topic, $event: any): void {
        $event.stopPropagation();
        this.forumService.toggleLikeTopic(item.id, !this.isLiked).subscribe();
    }

    getRandomColor(): any {
        // eslint-disable-next-line no-bitwise
        // return this.colorsArray[(Math.random() * this.colorsArray.length) | 0];
        return '#F25C42';
    }

    goToTopicEdit(id: string, $event: any): void {
        $event.stopPropagation();
        // const backUrl: string = this.router.url;
        this.router.navigate([`/forum/${id}`]);
    }

    goToTopicDetails(id: string): void {
        // const backUrl: string = this.router.url;
        this.router.navigate([`/forum/${id}/comments`]);
    }

    deleteTopic(id: string, $event: any): void {
        $event.stopPropagation();
        this.dialog
            .open(ConfirmDialogComponent, {
                width: '500px',
                data: {
                    title: 'Delete Topic',
                    message: 'Are you sure you want to delete this topic?',
                    yes: 'Yes',
                    noButtonVisible: true,
                    no: 'No',
                },
            })
            .beforeClosed()
            .pipe(
                take(1),
                switchMap((isConfirmed: boolean) => {
                    if (isConfirmed) {
                        return this.forumService.deleteTopic(id).pipe(
                            take(1),
                            tap(() => {
                                this.onRefresh();
                            })
                        );
                    }

                    return EMPTY;
                })
            )
            .subscribe();
    }

    onKeyUp(search: string): void {
        const topicsQueryParams: TopicsQueryParams = {
            search: search,
        };

        this.getAllTopics(topicsQueryParams);
    }
}
