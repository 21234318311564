import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';

@Pipe({ name: 'localizedMoment', pure: false })
@Injectable({ providedIn: 'root' })
export class LocalizedMomentPipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(value: any): string | null {
        if (moment.isMoment(value)) {
            return moment(value)
                .fromNow()
                .replace(
                    'a minute ago',
                    this.translocoService.translate('a minute ago')
                )
                .replace(
                    'an hour ago',
                    this.translocoService.translate('an hour ago')
                )
                .replace(
                    'a day ago',
                    this.translocoService.translate('a day ago')
                )
                .replace(
                    'minutes ago',
                    this.translocoService.translate('minutes ago')
                )
                .replace(
                    'hours ago',
                    this.translocoService.translate('hours ago')
                )
                .replace(
                    'days ago',
                    this.translocoService.translate('days ago')
                );
        }
    }
}
