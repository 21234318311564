<div class="search" [attr.type]="type">
    <label hidden [for]="placeholder + '_' + tempId">{{ placeholder }}</label>
    <span class="icon"><i class="far fa-search"></i></span>
    <input
        #search
        type="search"
        [attr.maxlength]="maxLength"
        (search)="__valueChanged($event.target.value)"
        (keyup)="__valueChanged($event.target.value)"
        (change)="onChange($event.target.value)"
        [placeholder]="placeholder"
        [value]="value != null ? value : ''"
        [id]="placeholder + '_' + tempId"
    />
</div>
