import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'vsc-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
    @Input() url: string;

    /**
     * Hide right-side divider.
     * Default is false.
     */
    @Input() hideRightDivider: boolean = false;

    constructor() {}
}
