const ADMIN_PAGE_PREFIX = '/admin';

abstract class InvitesPagePaths {
    private static readonly INVITES_PAGE_PREFIX = `${ADMIN_PAGE_PREFIX}/invites`;

    static readonly Root: string = `${InvitesPagePaths.INVITES_PAGE_PREFIX}`;
    static readonly Create: string = `${InvitesPagePaths.INVITES_PAGE_PREFIX}/create`;
    static readonly Edit: string = `${InvitesPagePaths.INVITES_PAGE_PREFIX}/{0}/details`;
}
abstract class VideosPagePaths {
    private static readonly VIDEOS_PAGE_PREFIX = `${ADMIN_PAGE_PREFIX}/videos`;

    static readonly Root: string = `${VideosPagePaths.VIDEOS_PAGE_PREFIX}`;
    static readonly Create: string = `${VideosPagePaths.VIDEOS_PAGE_PREFIX}/create`;
    static readonly Edit: string = `${VideosPagePaths.VIDEOS_PAGE_PREFIX}/{0}/details`;
}

abstract class AdminPagePaths {
    static readonly Root: string = `${ADMIN_PAGE_PREFIX}`;
    static readonly Reports: string = `${ADMIN_PAGE_PREFIX}/reports`;
    static readonly Invites: typeof InvitesPagePaths = InvitesPagePaths;
    static readonly Videos: typeof VideosPagePaths = VideosPagePaths;
}

abstract class PublicPagePaths {
    static readonly Home: string = '/';
    static readonly Chats: string = '/chats';
    static readonly Forum: string = '/forum';
    static readonly Classes: string = '/classes/upcoming';
    static readonly ClassLibrary: string = '/classes/past';
    static readonly VideoLibrary: string = '/videos/providers';
    static readonly Profile: string = '/profile';
    static readonly Cookies: string = '/cookies';
    static readonly Privacy: string = '/privacy-policy';
    static readonly Notifications: string = '/notifications';
    static readonly Calendar: string = '/calendar';
    static readonly ProfileNotifications: string = '/profile/notifications';
}

export abstract class PagePaths {
    static readonly Admin: typeof AdminPagePaths = AdminPagePaths;
    static readonly Public: typeof PublicPagePaths = PublicPagePaths;
}
