import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NgxPermissionsGuard } from 'ngx-permissions';

import { LeaveChatConfirmationGuard } from '../chats/chat-room/leave-chat-confirmation.guard';
import { LeaveClassConfirmationGuard } from '../classroom/leave-class-confirmation.guard';
import { ForumComponent } from './forum.component';
import { TopicGuard } from './guards/topic.guard';
import { TopicCommentsComponent } from './topic-comments/topic-comments.component';
import { TopicManageComponent } from './topic-manage/topic-manage.component';

const routes: Routes = [
    {
        path: '',
        component: ForumComponent,
        canActivate: [
            NgxPermissionsGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
    },
    {
        path: 'create',
        component: TopicManageComponent,
        canActivate: [NgxPermissionsGuard, TopicGuard],
    },
    {
        path: ':id',
        component: TopicManageComponent,
        canActivate: [NgxPermissionsGuard, TopicGuard],
    },
    {
        path: ':id/comments',
        component: TopicCommentsComponent,
        canActivate: [NgxPermissionsGuard, TopicGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ForumRoutingModule {}
