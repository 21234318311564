import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { setLoading } from '@datorama/akita';

import * as moment from 'moment';
import { EMPTY, Observable, catchError, map, tap } from 'rxjs';

import { Page, PageRequest } from '@shared/collection-view/page';
import { PagedEntities } from '@shared/models/paged-entities';
import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { Comment } from '../state/topic-state/comment';
import { Topic } from '../state/topic-state/topic';
import { TopicsQuery } from '../state/topic-state/topics.query';
import { TopicsStore } from '../state/topic-state/topics.store';
import { CommentUpdateService } from './comment-update.service';
import { ForumDataService } from './forum.dataservice';
import { TopicsQueryParams } from './query-params/topics.queryparams';

@Injectable({
    providedIn: 'root',
})
export class ForumService {
    constructor(
        private topicsStore: TopicsStore,
        private topicsQuery: TopicsQuery,
        private snackBar: SnackBarService,
        private forumDataService: ForumDataService,
        private router: Router,
        private commentUpdateService: CommentUpdateService
    ) {}

    page(
        request: PageRequest<Topic>,
        query: TopicsQueryParams
    ): Observable<Page<Topic>> {
        return this.forumDataService.getForumTopics(request, query).pipe(
            map((response: PagedEntities<Topic>) => {
                const entities: any = response.entities.map((topic: Topic) => ({
                    ...topic,
                }));
                this.topicsStore.set(entities);

                return {
                    content: response.entities,
                    size: response.entities.length,
                    totalElements: response.totalCount,
                    number: request.page,
                } as Page<Topic>;
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to load Topics.');
                return EMPTY;
            })
        );
    }

    getFlaggedTopics(
        request: PageRequest<Topic>,
        query: TopicsQueryParams
    ): Observable<Page<Topic>> {
        return this.forumDataService.getFlaggedTopics(request, query).pipe(
            map((response: PagedEntities<Topic>) => {
                const entities: any = response.entities.map((topic: Topic) => ({
                    ...topic,
                }));
                this.topicsStore.set(entities);

                return {
                    content: response.entities,
                    size: response.entities.length,
                    totalElements: response.totalCount,
                    number: request.page,
                } as Page<Topic>;
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to load Topics.');
                return EMPTY;
            })
        );
    }

    getAllTopics(
        queryParams: TopicsQueryParams
    ): Observable<PagedEntities<Topic>> {
        return this.forumDataService.getAllTopics(queryParams).pipe(
            map((pagedEntities: PagedEntities<Topic>) => {
                let entities: Topic[] = pagedEntities.entities;

                entities = entities.map((topic: Topic) => ({
                    ...topic,
                    createdAt: moment(topic.createdAt),
                    createdBy: this.transformCreatedBy(topic.createdBy),
                }));
                this.topicsStore.upsertMany(entities);

                entities.sort((a: Topic, b: Topic) => {
                    if (a.isPinned && !b.isPinned) {
                        return -1;
                    }
                    if (!a.isPinned && b.isPinned) {
                        return 1;
                    }

                    return (
                        moment(b.createdAt).valueOf() -
                        moment(a.createdAt).valueOf()
                    );
                });

                return {
                    entities: entities,
                    totalCount: pagedEntities.totalCount,
                } as PagedEntities<Topic>;
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to load Topics.');
                return EMPTY;
            })
        );
    }

    transformCreatedBy(createdBy: string): string {
        return (
            createdBy.split(' ')[0] +
            ' ' +
            createdBy.split(' ')[1].charAt(0) +
            '.'
        );
    }

    setBackUrl(backUrl: string): void {
        this.topicsStore.update({ backUrl });
    }

    upsert(topic: Topic): Observable<Topic> {
        return topic.id ? this.put(topic) : this.post(topic);
    }

    put(topicItem: Topic): Observable<Topic> {
        return this.forumDataService.put(topicItem).pipe(
            map((topic: Topic) => {
                this.topicsStore.update(topic.id, topic);
                this.snackBar.open('Topic updated.');
                this.router.navigate(['/forum']);
                return topic;
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to update Topic.');
                return EMPTY;
            })
        );
    }

    post(topicItem: Topic): Observable<Topic> {
        return this.forumDataService.post(topicItem).pipe(
            map((topic: Topic) => {
                this.topicsStore.add(topic);
                this.snackBar.open('Topic created.');
                this.router.navigate(['/forum']);
                return topic;
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to create Topic.');
                return EMPTY;
            })
        );
    }

    deleteTopic(id: string): Observable<any> {
        return this.forumDataService.deleteTopic(id).pipe(
            tap(() => {
                this.snackBar.open('Topic deleted.');
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to delete Topic.');
                return EMPTY;
            })
        );
    }

    flagTopic(id: string): Observable<any> {
        return this.forumDataService.flagTopic(id).pipe(
            tap(() => {
                this.snackBar.open('Topic flagged.');
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to flag Topic.');
                return EMPTY;
            })
        );
    }

    flagComment(id: string): Observable<any> {
        return this.forumDataService.flagComment(id).pipe(
            tap(() => {
                this.snackBar.open('Comment flagged.');
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to flag Comment.');
                return EMPTY;
            })
        );
    }

    togglePinTopic(topicId: string, isPinned: boolean): Observable<Topic> {
        return this.forumDataService.togglePinTopic(topicId, isPinned).pipe(
            map((topic: Topic) => {
                this.topicsStore.update(topic.id, topic);
                isPinned
                    ? this.snackBar.open('Topic pinned.')
                    : this.snackBar.open('Topic unpinned.');

                return topic;
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to toggle pin topic.');
                return EMPTY;
            })
        );
    }

    toggleLikeTopic(topicId: string, isLiked: boolean): Observable<Topic> {
        return this.forumDataService.toggleLikeTopic(topicId).pipe(
            map((topic: Topic) => {
                this.topicsStore.update(topic.id, topic);
                isLiked
                    ? this.snackBar.open('Topic liked.')
                    : this.snackBar.open('Topic unliked.');

                return topic;
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to toggle like topic.');
                return EMPTY;
            })
        );
    }

    toggleLikeComment(commentId: string, isLiked: boolean): Observable<any> {
        return this.forumDataService.toggleLikeComment(commentId).pipe(
            map((comment: any) => {
                isLiked
                    ? this.snackBar.open('Comment liked.')
                    : this.snackBar.open('Comment unliked.');

                return comment;
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to toggle like comment.');
                return EMPTY;
            })
        );
    }

    fetchTopicIfNotPresent(): Observable<Topic> {
        return this.getById(this.topicsQuery.getEditingId());
    }

    getById(id: string): Observable<Topic> {
        return this.forumDataService.getById(id).pipe(
            map((topicEntity: Topic) => {
                this.topicsStore.upsert(topicEntity.id, topicEntity);
                return {
                    ...topicEntity,
                    createdAt: moment(topicEntity.createdAt),
                    createdBy: this.transformCreatedBy(topicEntity.createdBy),
                };
            }),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to load Topic.');
                return EMPTY;
            })
        );
    }

    upsertComment(comment: Comment): Observable<Topic> {
        return this.addTopicComment(comment).pipe(
            tap(() => {
                this.commentUpdateService.notifyCommentAdded();
            })
        );
    }

    addTopicComment(comment: Comment): Observable<Topic> {
        return this.forumDataService.postComment(comment).pipe(
            tap((response: Topic) => {
                console.log(response);
                this.topicsStore.upsert(response.id, response);
                this.snackBar.open('Comment added.');
            }),
            map((response: Topic) => ({
                ...response,
                createdAt: moment(response.createdAt),
                createdBy: this.transformCreatedBy(response.createdBy),
            })),
            setLoading(this.topicsStore),
            catchError(() => {
                this.snackBar.open('Failed to add comment.');
                return EMPTY;
            })
        );
    }
}
