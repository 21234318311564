<mat-nav-list>
    <vsc-menu-item
        icon="house"
        path="/home"
        basePath="/home"
        title="Home"
        (menuItemClick)="onMenuItemClick()"
        *ngxPermissionsOnly="Permissions.Homepage.View"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="book"
        [path]="PagePaths.Public.Classes"
        [basePath]="PagePaths.Public.Classes"
        title="Live Programs"
        (menuItemClick)="onMenuItemClick()"
        *ngxPermissionsOnly="Permissions.Classes.View"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="atlas"
        [path]="PagePaths.Public.ClassLibrary"
        [basePath]="PagePaths.Public.ClassLibrary"
        title="Recorded Programs"
        (menuItemClick)="onMenuItemClick()"
        *ngxPermissionsOnly="Permissions.Classes.View"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="clapperboard-play"
        [path]="PagePaths.Public.VideoLibrary"
        [basePath]="PagePaths.Public.VideoLibrary"
        title="Channels"
        (menuItemClick)="onMenuItemClick()"
        *ngxPermissionsOnly="Permissions.Videos.View"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="calendar-lines"
        path="/calendar"
        basePath="/calendar"
        title="My Calendar"
        (menuItemClick)="onMenuItemClick()"
        *ngxPermissionsOnly="Permissions.Calendar.View"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="message-dots"
        path="/chats"
        basePath="/chats"
        title="Chat"
        class="chat-menu-item"
        (menuItemClick)="onMenuItemClick()"
        [note]="chatInvitesCountNote$ | async"
        *ngxPermissionsOnly="Permissions.Chat.View"
    >
    </vsc-menu-item>
    <vsc-menu-item
        icon="message-pen"
        title="Forum"
        path="/forum"
        basePath="/forum"
        (menuItemClick)="onMenuItemClick()"
        *ngIf="this.userForumEnabled$ | async"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="window"
        path="/internet"
        basePath="/internet"
        title="Internet"
        (menuItemClick)="onMenuItemClick()"
        *ngxPermissionsOnly="Permissions.Internet.View"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="newspaper"
        path="/news"
        basePath="/news"
        title="News"
        (menuItemClick)="onMenuItemClick()"
        *ngxPermissionsOnly="Permissions.News.View"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="sun-cloud"
        path="/weather"
        basePath="/weather"
        title="Weather"
        (menuItemClick)="onMenuItemClick()"
        *ngxPermissionsOnly="Permissions.Weather.View"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="gamepad-modern"
        path="/games"
        basePath="/games"
        title="Games"
        (menuItemClick)="onMenuItemClick()"
        *ngxPermissionsOnly="Permissions.Internet.View"
    ></vsc-menu-item>
    <vsc-menu-item
        icon="envelope"
        title="Gmail"
        (menuItemClick)="openMail()"
    ></vsc-menu-item>
</mat-nav-list>
