import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable, Subscription, filter, finalize, take, tap } from 'rxjs';

import { AuthService } from '@app/core/auth/auth.service';

import { Constants } from '@shared/constants';
import { Validators } from '@shared/forms/validators/validators';
import { Lookup } from '@shared/state/lookup/lookup';

import { ForumService } from '../services/forum.service';
import { Topic } from '../state/topic-state/topic';
import { TopicsQuery } from '../state/topic-state/topics.query';

@Component({
    selector: 'vsc-topic-manage',
    templateUrl: './topic-manage.component.html',
    styleUrls: ['./topic-manage.component.scss'],
})
export class TopicManageComponent implements OnInit, OnDestroy {
    isEditMode: boolean;
    topic: Topic;
    formGroup: FormGroup;
    organizations$: Observable<Array<Lookup>> =
        this.topicsQuery.selectOrganizationsWithoutLoggedOne$;

    forumCategories$: Observable<Array<Lookup>> =
        this.topicsQuery.selectForumCategories$;

    isEditMode$: Observable<boolean> = this.topicsQuery.selectIsEditMode$;
    backUrl$: Observable<string> = this.topicsQuery.selectBackUrl$;
    isLoading$: Observable<boolean> = this.topicsQuery.selectLoading();
    topic$: Observable<Topic> = this.topicsQuery.selectActiveTopic$.pipe(
        filter(Boolean),
        take(1),
        tap((topicEntity: Topic) => {
            if (topicEntity && !this.topic) {
                this.topic = topicEntity;
            }
        })
    );

    subscriptions: Subscription[] = [];

    constructor(
        private topicsQuery: TopicsQuery,
        private forumService: ForumService,
        private formBuilder: FormBuilder,
        private authService: AuthService
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
        this.forumService.setBackUrl(Constants.FORUM_DEFAULT_BACK_URL);
    }

    ngOnInit(): void {
        this.isEditMode = this.topicsQuery.getIsEditMode();
        this.subscriptions.push(this.organizations$.subscribe());
        this.subscriptions.push(this.forumCategories$.subscribe());
        this.formGroup = this.formBuilder.group({
            id: null,
            title: [null, [Validators.maxLength(100), Validators.required()]],
            content: [
                null,
                [Validators.maxLength(1000), Validators.required()],
            ],
            forumCategoryId: [null, [Validators.required()]],
            userId: this.authService.userId,
            isAnonymous: [false],
        });
    }

    save(): void {
        if (this.formGroup.valid && !this.formGroup.pristine) {
            this.upsertTopic().subscribe();
        } else {
            this.formGroup.markAllAsTouched();
        }
    }

    private upsertTopic(): Observable<Topic> {
        const formValue: any = { ...this.formGroup.value };
        return this.forumService.upsert(formValue).pipe(
            take(1),
            finalize(() => {
                this.formGroup.markAsPristine();
            })
        );
    }
}
