<div fxFlex fxLayout="column">
    <label *ngIf="label" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutGap="4">
            <span
                >{{ label | transloco }}
                {{ isRequired(formControl) ? '*' : '' }}</span
            >
            <vsc-icon
                *ngIf="this.infoTooltipHint"
                [matTooltip]="this.infoTooltipHint | transloco"
                class="vsc-icon-hint"
                iconStyle="solid"
                color="accent"
                >circle-info</vsc-icon
            >
        </div>
    </label>

    <div *ngIf="description" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutGap="4">
            <span class="description">
                {{ description | transloco }}
            </span>
        </div>
    </div>

    <mat-form-field
        *ngIf="searchable"
        [class.pt-17]="!label"
        appearance="outline"
        floatLabel="never"
    >
        <mat-select
            *ngIf="!multiple"
            [compareWith]="compareFn"
            [placeholder]="placeholder | transloco"
            [aria-label]="placeholder | transloco"
            (blur)="onBlur(formControl)"
            (selectionChange)="__onSelectionChange($event.value)"
            [value]="valueRef || value"
            appearance="outline"
            [disabled]="readonly"
            (openedChange)="openedChange()"
        >
            <div fxLayout="row" class="select-search">
                <button matPrefix mat-icon-button disabled="true">
                    <vsc-icon>search</vsc-icon>
                </button>
                <label hidden for="selectSearch">{{
                    searchPlaceholder | transloco
                }}</label>
                <input
                    #search
                    matInput
                    type="search"
                    [formControl]="searchTextboxControl"
                    class="search-input"
                    placeholder="{{ searchPlaceholder | transloco }}"
                    (keyup)="onKeyUp($event.target.value)"
                    (keydown)="handleInput($event)"
                    color="primary"
                    autocomplete="off"
                    id="selectSearch"
                />
                <button
                    *ngIf="this.searchTextboxControl.value"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="openedChange()"
                    class="close-button"
                >
                    <vsc-icon>close</vsc-icon>
                </button>
            </div>
            <mat-option
                (onSelectionChange)="optionSelectionChange($event)"
                *ngFor="let item of filteredOptions"
                [value]="valueMapper(item)"
                [disabled]="item?.isDefaultValue"
            >
                {{
                    isTranslatable
                        ? (textMapper(item) | transloco)
                        : textMapper(item)
                }}
            </mat-option>
        </mat-select>
        <mat-select
            *ngIf="multiple"
            multiple
            [placeholder]="placeholder | transloco"
            [aria-label]="placeholder | transloco"
            (blur)="onBlur(formControl)"
            (selectionChange)="__onSelectionChange($event.value)"
            [value]="formControl.value != null ? formControl.value : ''"
            appearance="outline"
            [disabled]="readonly"
            (openedChange)="openedChange()"
        >
            <div fxLayout="row" class="select-search">
                <button matPrefix mat-icon-button disabled="true">
                    <vsc-icon>search</vsc-icon>
                </button>
                <label hidden for="selectSearch">{{
                    searchPlaceholder | transloco
                }}</label>
                <input
                    #search
                    matInput
                    type="search"
                    [formControl]="searchTextboxControl"
                    class="search-input"
                    placeholder="{{ searchPlaceholder | transloco }}"
                    (keyup)="onKeyUp($event.target.value)"
                    (keydown)="handleInput($event)"
                    color="primary"
                    autocomplete="off"
                    id="selectSearch"
                />
                <button
                    *ngIf="this.searchTextboxControl.value"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="openedChange()"
                    class="close-button"
                >
                    <vsc-icon>close</vsc-icon>
                </button>
            </div>

            <mat-checkbox
                *ngIf="isSelectAllEnabled"
                fxFlex="100"
                fxLayout="row"
                class="mat-option"
                (click)="handleInput($event)"
                (change)="toggleSelection($event)"
                id="selectAllCheckboxId"
                [(ngModel)]="isSelectAllChecked"
                [checked]="isSelectAllCheckedFn()"
            >
                {{ selectAllText | transloco }}
            </mat-checkbox>
            <mat-option
                (onSelectionChange)="optionSelectionChange($event)"
                *ngFor="let item of filteredOptions"
                [value]="valueMapper(item)"
                [disabled]="item?.isDefaultValue"
            >
                {{
                    isTranslatable
                        ? (textMapper(item) | transloco)
                        : textMapper(item)
                }}
            </mat-option>
        </mat-select>
        <mat-error>
            <vsc-validation-message
                *ngIf="formControl"
                [controlName]="formControlName"
            ></vsc-validation-message>
        </mat-error>
    </mat-form-field>

    <mat-form-field
        *ngIf="!searchable"
        [class.pt-17]="!label"
        appearance="outline"
        floatLabel="never"
    >
        <mat-select
            *ngIf="multiple"
            multiple
            [placeholder]="placeholder | transloco"
            [aria-label]="placeholder | transloco"
            (blur)="onBlur(formControl)"
            (selectionChange)="__onSelectionChange($event.value)"
            [value]="formControl.value != null ? formControl.value : ''"
            appearance="outline"
            [disabled]="readonly"
        >
            <mat-option
                *ngFor="let item of options"
                [value]="valueMapper(item)"
                [disabled]="item?.isDefaultValue"
            >
                {{
                    isTranslatable
                        ? (textMapper(item) | transloco)
                        : textMapper(item)
                }}
            </mat-option>
        </mat-select>
        <mat-select
            *ngIf="!multiple"
            [compareWith]="compareFn"
            [placeholder]="placeholder | transloco"
            [aria-label]="placeholder | transloco"
            (blur)="onBlur(formControl)"
            (selectionChange)="__onSelectionChange($event.value)"
            [value]="valueRef || value"
            appearance="outline"
            [disabled]="readonly"
        >
            <ng-container *ngFor="let item of options">
                <mat-option
                    [value]="valueMapper(item)"
                    *ngIf="shouldDisplayOption(item)"
                >
                    {{
                        isTranslatable
                            ? (textMapper(item) | transloco)
                            : textMapper(item)
                    }}
                </mat-option>
            </ng-container>
        </mat-select>
        <mat-hint *ngIf="hint !== ''">{{ hint | transloco }}</mat-hint>
        <mat-error>
            <vsc-validation-message
                *ngIf="formControl"
                [controlName]="formControlName"
            ></vsc-validation-message>
        </mat-error>
    </mat-form-field>
</div>
