import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { PageRequest } from '@shared/collection-view/page';
import { PagedEntities } from '@shared/models/paged-entities';
import { QueryParamsUtils } from '@shared/utils/query-params.utils';

import { Comment } from '../state/topic-state/comment';
import { ForumCategory, Topic } from '../state/topic-state/topic';
import { TopicsQueryParams } from './query-params/topics.queryparams';

@Injectable({
    providedIn: 'root',
})
export class ForumDataService {
    readonly FORUM_API_PATH: string = `${environment.apiUrl}/vsc`;
    constructor(private httpClient: HttpClient) {}

    getAllTopics(
        queryParams: TopicsQueryParams
    ): Observable<PagedEntities<Topic>> {
        const params: HttpParams = new HttpParams({
            fromObject: QueryParamsUtils.convertParametersToString({
                ...queryParams,
            }),
        });
        return this.httpClient.get<PagedEntities<Topic>>(
            `${this.FORUM_API_PATH}/forum/topic`,
            {
                params,
            }
        );
    }

    getForumTopics(
        request: PageRequest<Topic>,
        query: TopicsQueryParams
    ): Observable<PagedEntities<Topic>> {
        const params: HttpParams = new HttpParams({
            fromObject: QueryParamsUtils.convertParametersToString({
                ...query,
            }),
        });
        return this.httpClient.get<PagedEntities<Topic>>(
            `${this.FORUM_API_PATH}/forum/topic`,
            {
                params,
            }
        );
    }

    getFlaggedTopics(
        request: PageRequest<Topic>,
        query: TopicsQueryParams
    ): Observable<PagedEntities<Topic>> {
        const params: HttpParams = new HttpParams({
            fromObject: QueryParamsUtils.convertParametersToString({
                ...query,
            }),
        });
        return this.httpClient.get<PagedEntities<Topic>>(
            `${this.FORUM_API_PATH}/forum/topic/flagged`,
            {
                params,
            }
        );
    }

    getById(id: string): Observable<Topic> {
        return this.httpClient.get<Topic>(
            `${this.FORUM_API_PATH}/forum/topic/${id}`
        );
    }

    getForumCategories(): Observable<Array<ForumCategory>> {
        return this.httpClient.get<Array<ForumCategory>>(
            `${this.FORUM_API_PATH}/forumCategory/forum-categories`
        );
    }

    put(topic: Topic): Observable<Topic> {
        return this.httpClient.patch<Topic>(
            `${this.FORUM_API_PATH}/forum/topic`,
            topic
        );
    }

    post(topic: Topic): Observable<Topic> {
        return this.httpClient.post<Topic>(
            `${this.FORUM_API_PATH}/forum/topic`,
            topic
        );
    }

    deleteTopic(id: string): Observable<any> {
        return this.httpClient.delete(
            `${this.FORUM_API_PATH}/forum/topic/${id}`
        );
    }

    flagTopic(id: string): Observable<any> {
        return this.httpClient.patch(
            `${this.FORUM_API_PATH}/forum/topic/${id}/flag`,
            {}
        );
    }

    flagComment(id: string): Observable<any> {
        return this.httpClient.patch(
            `${this.FORUM_API_PATH}/forum/comment/${id}/flag`,
            {}
        );
    }

    togglePinTopic(id: string, isPinned: boolean): Observable<Topic> {
        return this.httpClient.patch<Topic>(
            `${this.FORUM_API_PATH}/forum/topic/${id}/toggle-pin-topic?isPinned=${isPinned}`,
            {}
        );
    }

    toggleLikeTopic(id: string): Observable<Topic> {
        return this.httpClient.patch<Topic>(
            `${this.FORUM_API_PATH}/forum/topic/${id}/toggle-like-topic`,
            {}
        );
    }

    toggleLikeComment(id: string): Observable<Topic> {
        return this.httpClient.patch<Topic>(
            `${this.FORUM_API_PATH}/forum/comment/${id}/toggle-like-comment`,
            {}
        );
    }

    postComment(comment: Comment): Observable<Topic> {
        return this.httpClient.post<Topic>(
            `${this.FORUM_API_PATH}/forum/comment`,
            comment
        );
    }

    postReply(reply: any): Observable<Topic> {
        return this.httpClient.post<Topic>(
            `${this.FORUM_API_PATH}/forum/reply/${reply.commentId}`,
            reply
        );
    }
}
