import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { EMPTY, switchMap, take, tap } from 'rxjs';

import { AuthService } from '@app/core/auth/auth.service';

import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { Constants } from '@shared/constants';

import { ForumService } from '../services/forum.service';
import { TopicsQueryParams } from '../services/query-params/topics.queryparams';
import { Like } from '../state/topic-state/like';
import { Topic } from '../state/topic-state/topic';

@Component({
    selector: 'vsc-topic-list-item',
    templateUrl: './topic-list-item.component.html',
    styleUrls: ['./topic-list-item.component.scss'],
})
export class TopicListItemComponent implements OnInit {
    imageUrl: string = 'https://picsum.photos/seed/picsum/80/80';
    _item: Topic;
    isLiked: boolean = false;
    canEdit: boolean = false;
    colorsArray: Array<string> = Constants.AVATAR_COLORS;
    _isUserInRole: boolean;
    constructor(
        private forumService: ForumService,
        private authService: AuthService,
        private router: Router,
        private dialog: MatDialog
    ) {}

    get item(): Topic {
        return this._item;
    }

    @Input() set item(value: Topic) {
        this._item = value;
    }

    ngOnInit(): void {
        const like: Like = this.item.likeDtos.find(
            (x: Like) => x.userId === this.authService.userId
        );

        this.isLiked = like !== null && like !== undefined;
    }

    togglePinTopic(item: Topic, $event: any): void {
        $event.stopPropagation();
        this.forumService.togglePinTopic(item.id, !item.isPinned).subscribe();
    }

    toggleLikeTopic(item: Topic, $event: any): void {
        $event.stopPropagation();
        this.forumService
            .toggleLikeTopic(item.id, !this.isLiked)
            .subscribe(() => window.location.reload());
    }

    getRandomColor(): any {
        // eslint-disable-next-line no-bitwise
        // return this.colorsArray[(Math.random() * this.colorsArray.length) | 0];
        return '#F25C42';
    }

    goToTopicEdit(id: string, $event: any): void {
        $event.stopPropagation();
        // const backUrl: string = this.router.url;
        this.router.navigate([`/forum/${id}`]);
    }

    goToTopicDetails(id: string): void {
        // const backUrl: string = this.router.url;
        this.router.navigate([`/forum/${id}/comments`]);
    }

    deleteTopic(id: string, $event: any): void {
        $event.stopPropagation();
        this.dialog
            .open(ConfirmDialogComponent, {
                width: '500px',
                data: {
                    title: 'Delete Topic',
                    message: 'Are you sure you want to delete this topic?',
                    yes: 'Yes',
                    noButtonVisible: true,
                    no: 'No',
                },
            })
            .beforeClosed()
            .pipe(
                take(1),
                switchMap((isConfirmed: boolean) => {
                    if (isConfirmed) {
                        return this.forumService.deleteTopic(id).pipe(
                            take(1),
                            tap(() => {
                                const topicsQueryParams: TopicsQueryParams = {
                                    search: '',
                                    page: 0,
                                    pageSize: 10,
                                };

                                this.forumService
                                    .getAllTopics(topicsQueryParams)
                                    .pipe(
                                        take(1),
                                        tap(() => {
                                            window.location.reload();
                                        })
                                    )
                                    .subscribe();
                            })
                        );
                    }

                    return EMPTY;
                })
            )
            .subscribe();
    }

    flagTopic(id: string, $event: any): void {
        $event.stopPropagation();
        this.dialog
            .open(ConfirmDialogComponent, {
                width: '500px',
                data: {
                    title: 'Flag Topic',
                    message: 'Are you sure you want to flag this topic?',
                    yes: 'Yes',
                    noButtonVisible: true,
                    no: 'No',
                },
            })
            .beforeClosed()
            .pipe(
                take(1),
                switchMap((isConfirmed: boolean) => {
                    if (isConfirmed) {
                        return this.forumService.flagTopic(id).pipe(take(1));
                    }
                })
            )
            .subscribe();
    }
}
