<div
    [ngClass]="{ event: !item.isPinned, pinned: item.isPinned }"
    fxLayout="column"
    fxLayoutAlign="space-between strech"
    fxFlex="100"
    fxFlex.lt-md="50"
    [attr.clickable]="true"
>
    <div class="content">
        <div class="wrapper" fxLayout="row">
            <div
                class="image-container"
                [ngStyle]="{ 'background-color': getRandomColor() }"
            >
                <span class="avatar">
                    <ng-container *ngIf="item.isAnonymous; else notAnonymous"
                        >A</ng-container
                    >
                    <ng-template #notAnonymous>
                        {{ item.createdBy.charAt(0) }}
                    </ng-template>
                </span>
            </div>

            <div class="body" fxFlex>
                <div class="main" fxFlex fxLayout="row" fxLayoutGap="20px">
                    <div class="info" fxFlex="70" fxLayout="column">
                        <!-- Title and Organizer -->
                        <div
                            fxFlex
                            fxLayout="column"
                            fxLayoutAlign="start start"
                            class="title-organizer"
                            (click)="goToTopicDetails(item.id)"
                        >
                            <!-- Category -->
                            <div
                                class="topic-category"
                                fxLayout="row"
                                fxLayoutAlign="start center"
                            >
                                <span>
                                    {{
                                        item.forumCategoryDto.name
                                            | transloco
                                            | uppercase
                                    }}
                                </span>
                            </div>
                            <!-- /Category -->
                            <!-- Title -->
                            <div
                                class="title"
                                fxLayout="row"
                                fxLayoutAlign="start center"
                            >
                                <div fxLayout="column">
                                    <h2 class="p-0">
                                        {{ item.title | transloco }}
                                    </h2>
                                </div>
                                <div fxLayout="column" class="ml-10">
                                    <div fxLayout="row" class="action-icons">
                                        <span
                                            *ngIf="item.isUserInRole"
                                            (click)="
                                                togglePinTopic(item, $event)
                                            "
                                        >
                                            <vsc-icon
                                                *ngIf="item.isPinned"
                                                iconStyle="solid"
                                                >thumbtack</vsc-icon
                                            >
                                            <vsc-icon
                                                *ngIf="!item.isPinned"
                                                iconStyle="regular"
                                                >thumbtack</vsc-icon
                                            >
                                        </span>
                                        <vsc-icon
                                            *ngIf="item.canEdit"
                                            (click)="
                                                goToTopicEdit(item.id, $event)
                                            "
                                            >pen</vsc-icon
                                        >
                                        <vsc-icon
                                            *ngIf="
                                                item.canEdit ||
                                                item.isUserInRole
                                            "
                                            (click)="
                                                deleteTopic(item.id, $event)
                                            "
                                            >trash-can</vsc-icon
                                        >
                                        <vsc-icon
                                            class="closedTopicIcon"
                                            *ngIf="item.isClosed"
                                            iconStyle="solid"
                                            >lock</vsc-icon
                                        >
                                        <vsc-icon
                                            class="flagTopicIcon"
                                            iconStyle="regular"
                                            (click)="flagTopic(item.id, $event)"
                                            >flag</vsc-icon
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- /Title -->
                            <!-- Organizer -->
                            <div
                                class="organizer mt-5"
                                fxLayout="row"
                                fxLayoutAlign="start center"
                            >
                                <ng-container
                                    *ngIf="
                                        item.isAnonymous;
                                        else notAnonymousCreatedBy
                                    "
                                >
                                    <span>Anonymous</span>
                                </ng-container>
                                <ng-template #notAnonymousCreatedBy>
                                    <span>{{ item.createdBy }}</span>
                                </ng-template>

                                &nbsp; <span>{{ 'created' | transloco }}</span
                                >&nbsp;
                                <span>{{
                                    item.createdAt | localizedMoment
                                }}</span>
                            </div>
                            <!-- /Organizer -->
                        </div>
                        <!-- /Title and Organizer -->
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="30"
                        fxLayoutAlign="space-between end"
                    >
                        <div fxLayout="row">
                            <div fxLayout="column" class="like-count-icon">
                                <div
                                    fxLayout="row"
                                    (click)="toggleLikeTopic(item, $event)"
                                >
                                    <vsc-icon
                                        *ngIf="item.isLiked"
                                        iconStyle="solid"
                                        >heart</vsc-icon
                                    >
                                    <vsc-icon
                                        *ngIf="!item.isLiked"
                                        iconStyle="regular"
                                        >heart</vsc-icon
                                    >
                                    <p class="like-count">
                                        {{ item.likeDtos.length }}
                                    </p>
                                </div>
                            </div>
                            <div fxLayout="column" class="comment-count-icon">
                                <div fxLayout="row">
                                    <vsc-icon>comment</vsc-icon>
                                    <p class="comment-count">
                                        {{ item.topicInteractionCount }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
