import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Constants } from '@shared/constants';

import { Topic } from './topic';

export interface TopicsState extends EntityState<Topic> {
    backUrl: string;
}

export const initialState: TopicsState = {
    loading: false,
    backUrl: Constants.FORUM_DEFAULT_BACK_URL,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'topics' })
export class TopicsStore extends EntityStore<TopicsState, Topic> {
    constructor() {
        super(initialState);
    }
}
