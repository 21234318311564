import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { PagedEntities } from '@shared/models/paged-entities';
import { PageRequest } from '@shared/table/page';

import { HelpPage } from '../manage/help-page/state/help-page';
import { Organization } from '../state/organization';
import { OrganizationSearchQuery } from './organization.service';

@Injectable({ providedIn: 'root' })
export class OrganizationDataService {
    constructor(private httpClient: HttpClient) {}

    getOrganizations(
        request: PageRequest<Organization>,
        query: OrganizationSearchQuery
    ): Observable<PagedEntities<Organization>> {
        const params = new HttpParams({
            fromObject: {
                search: query.search ?? '',
                page: request.page.toString(),
                pageSize: request.size.toString(),
                sortBy: request.sort.property,
                sortDirection: request.sort.order,
            },
        });

        return this.httpClient.get<PagedEntities<Organization>>(
            environment.apiUrl + '/ag/organizations',
            {
                params,
            }
        );
    }
    getOrganizationsWithContentExchangeEnabled(): Observable<Organization[]> {
        return this.httpClient.get<Organization[]>(
            environment.apiUrl + '/vsc/organizations/content-exchange/enabled'
        );
    }

    getOrganizationsWithForumContentExchangeEnabled(): Observable<
        Organization[]
    > {
        return this.httpClient.get<Organization[]>(
            environment.apiUrl +
                '/vsc/organizations/forum-content-exchange/enabled'
        );
    }

    getOrganizationsWithProfilePersonalizationAllowed(): Observable<
        Organization[]
    > {
        return this.httpClient.get<Organization[]>(
            environment.apiUrl +
                '/vsc/organizations/profile-personalization/allowed'
        );
    }

    getById(id: string): Observable<Organization> {
        return this.httpClient.get<Organization>(
            environment.apiUrl + `/ag/organizations/${id}`
        );
    }

    getOrganizationLogoUrl(id: string): Observable<string> {
        return this.httpClient.get(
            environment.apiUrl + `/ag/organizations/${id}/logo`,
            { responseType: 'text' }
        );
    }

    exists(name: string): Observable<boolean> {
        const encodedName = encodeURIComponent(name);
        return this.httpClient.get<boolean>(
            environment.apiUrl + `/ag/organizations/exists/${encodedName}`
        );
    }

    post(organization: any): Observable<any> {
        return this.httpClient.post<any>(
            environment.apiUrl + `/ag/organizations`,
            organization
        );
    }

    put(organization: any): Observable<any> {
        return this.httpClient.put<any>(
            environment.apiUrl + `/ag/organizations`,
            organization
        );
    }

    delete(id: string): Observable<any> {
        return this.httpClient.delete(
            environment.apiUrl + `/vsc/organizations/${id}`
        );
    }
    getHelpPageByOrganizationId(organizationId: string): Observable<HelpPage> {
        return this.httpClient.get<HelpPage>(
            environment.apiUrl + `/vsc/organizations/${organizationId}/help`
        );
    }
    createHelpPage(
        helpPage: HelpPage,
        organizationId: string
    ): Observable<HelpPage> {
        return this.httpClient.post<any>(
            environment.apiUrl + `/vsc/organizations/${organizationId}/help`,
            helpPage
        );
    }

    updateHelpPage(
        helpPage: HelpPage,
        organizationId: string
    ): Observable<HelpPage> {
        return this.httpClient.put<any>(
            environment.apiUrl + `/vsc/organizations/${organizationId}/help`,
            helpPage
        );
    }

    activateOrganization(id: string): Observable<Organization> {
        return this.httpClient.patch<Organization>(
            `${environment.apiUrl}/ag/organizations/${id}/activate`,
            {}
        );
    }

    deactivateOrganization(id: string): Observable<Organization> {
        return this.httpClient.patch<Organization>(
            `${environment.apiUrl}/ag/organizations/${id}/deactivate`,
            {}
        );
    }
}
