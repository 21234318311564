<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<ng-container *ngIf="this.topic$ | async">
    <div fxLayout="column" fxFlex="77" class="topic-comments">
        <!-- Sticky header -->
        <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="topic-sticky-header"
        >
            <div fxLayout="column" fxFlex="50">
                <div
                    fxLayout="row"
                    class="topic-category"
                    fxLayoutAlign="start center"
                >
                    <span>
                        {{
                            topic.forumCategoryDto.name | transloco | uppercase
                        }}
                    </span>
                </div>
                <div fxLayout="row" class="title" fxLayoutAlign="start center">
                    <h2 class="pt-3">{{ this.topic.title | transloco }}</h2>
                </div>
            </div>
            <div
                fxLayout="column"
                fxFlex="50"
                fxLayoutAlign="space-between end"
            >
                <button
                    mat-flat-button
                    color="primary"
                    class="add-comment"
                    (click)="scrollToCommentArea()"
                >
                    {{ 'Add comment' | transloco }}
                </button>
            </div>
        </div>
        <!-- /Sticky header -->

        <!-- Original Topic -->
        <div fxLayout="row" fxLayoutAlign="space-between center" class="topic">
            <div fxLayout="column" fxFlex="100">
                <div fxLayout="row" class="topic-content">
                    <!-- Organizer Avatar -->
                    <div fxLayout="column" class="organizer-avatar">
                        <div fxLayout="row">
                            <div
                                class="image-container"
                                [ngStyle]="{
                                    'background-color': getRandomColor()
                                }"
                            >
                                <span class="avatar">
                                    {{ this.topic.createdBy.charAt(0) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- /Organizer Avatar -->

                    <div fxLayout="column" class="topic-content-details">
                        <!-- Topic Organizer -->
                        <div fxLayout="row" class="mt-10">
                            <div
                                fxLayout="column"
                                fxFlex="50"
                                class="organizer"
                            >
                                <span
                                    ><span class="organizer-name">{{
                                        this.topic.createdBy | transloco
                                    }}</span>
                                    {{ 'created' | transloco }}
                                    {{
                                        this.topic.createdAt | localizedMoment
                                    }}</span
                                >
                            </div>
                            <div
                                fxLayout="column"
                                fxFlex="50"
                                fxLayoutAlign="space-between end"
                            >
                                <!-- ikonice -->
                            </div>
                        </div>
                        <!-- /Topic Organizer -->

                        <!-- Topic Content -->
                        <div fxLayout="row" class="mt-10" class="content">
                            {{ this.topic.content }}
                        </div>
                        <!-- /Topic Content -->

                        <!-- Topic Likes and Comments -->
                        <div fxLayout="row" class="likes-replies">
                            <!-- Topic Likes and Comments Count -->
                            <div fxLayout="column">
                                <div fxLayout="row">
                                    <div
                                        fxLayout="column"
                                        class="like-count-icon"
                                        (click)="
                                            toggleLikeTopic(this.topic, $event)
                                        "
                                    >
                                        <div fxLayout="row">
                                            <vsc-icon
                                                *ngIf="this.topic.isLiked"
                                                iconStyle="solid"
                                                >heart</vsc-icon
                                            >
                                            <vsc-icon
                                                *ngIf="!this.topic.isLiked"
                                                iconStyle="regular"
                                                >heart</vsc-icon
                                            >
                                            <p class="like-count">
                                                {{ this.topic.likeDtos.length }}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        fxLayout="column"
                                        class="comment-count-icon"
                                    >
                                        <div fxLayout="row">
                                            <vsc-icon>comment</vsc-icon>
                                            <p class="comment-count">
                                                {{
                                                    this.topic
                                                        .topicInteractionCount
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Topic Likes and Comments Count -->

                            <!-- Topic Reply Button -->
                            <div
                                fxLayout="column"
                                fxFlex="50"
                                fxLayoutAlign="space-between end"
                                class="reply-btn"
                            >
                                <button
                                    mat-flat-button
                                    color="primary"
                                    class="add-reply"
                                    (click)="scrollToCommentArea()"
                                >
                                    {{ 'Reply' | transloco }}
                                </button>
                            </div>
                            <!-- /Topic Reply Button -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Original Topic -->

        <!-- Topic Comments and Replies -->
        <ng-container *ngIf="this.topic.commentDtos.length !== 0">
            <ng-container *ngFor="let comment of this.topic.commentDtos">
                <div
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    class="topic"
                    [attr.id]="comment.id"
                >
                    <div fxLayout="column" fxFlex="100">
                        <div fxLayout="row" class="topic-content">
                            <!-- Organizer Avatar -->
                            <div fxLayout="column" class="organizer-avatar">
                                <div fxLayout="row">
                                    <div
                                        class="image-container"
                                        [ngStyle]="{
                                            'background-color': getRandomColor()
                                        }"
                                    >
                                        <span class="avatar">
                                            {{
                                                this.comment.createdBy.charAt(0)
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- /Organizer Avatar -->

                            <div
                                fxLayout="column"
                                class="topic-content-details"
                            >
                                <!-- Topic Organizer -->
                                <div fxLayout="row" class="mt-10">
                                    <div
                                        fxLayout="column"
                                        fxFlex="50"
                                        class="organizer"
                                    >
                                        <span
                                            ><span class="organizer-name">{{
                                                this.comment.createdBy.split(
                                                    ' '
                                                )[0] +
                                                    ' ' +
                                                    this.comment.createdBy
                                                        .split(' ')[1]
                                                        .charAt(0) +
                                                    '.'
                                            }}</span>
                                            {{ 'replied' | transloco }}
                                            {{ this.comment.createdAt }}</span
                                        >
                                    </div>
                                    <div
                                        fxLayout="column"
                                        fxFlex="50"
                                        fxLayoutAlign="space-between end"
                                    >
                                        <vsc-icon
                                            class="flagTopicIcon"
                                            iconStyle="regular"
                                            (click)="
                                                flagComment(
                                                    this.comment.id,
                                                    $event
                                                )
                                            "
                                            >flag</vsc-icon
                                        >
                                    </div>
                                </div>
                                <!-- /Topic Organizer -->

                                <!-- If this is a reply, show parent comment -->
                                <div
                                    *ngIf="
                                        this.comment.parentCommentId !== null &&
                                        this.comment.parentComment !== null
                                    "
                                    (click)="
                                        scrollToComment(
                                            this.comment.parentCommentId
                                        )
                                    "
                                    fxLayout="row"
                                    class="reply-fix-height"
                                >
                                    <div
                                        fxLayout="column"
                                        fxFlex="100"
                                        class="m-10"
                                    >
                                        <div fxLayout="row" fxFlex="100">
                                            <span>
                                                <span class="organizer-name">
                                                    {{
                                                        this.comment.parentComment.createdBy.split(
                                                            ' '
                                                        )[0] +
                                                            ' ' +
                                                            this.comment.parentComment.createdBy
                                                                .split(' ')[1]
                                                                .charAt(0) +
                                                            '.'
                                                    }}
                                                </span>
                                                {{ 'replied' | transloco }}
                                                {{
                                                    this.comment.parentComment
                                                        .createdAt
                                                }}
                                            </span>
                                        </div>
                                        <div
                                            fxLayout="row"
                                            fxFlex="100"
                                            class="truncate-content"
                                        >
                                            {{
                                                this.comment.parentComment
                                                    .content
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <!-- /If this is a reply, show parent comment -->

                                <!-- Topic Content -->
                                <div
                                    fxLayout="row"
                                    class="mt-10"
                                    class="content"
                                >
                                    {{ this.comment.content }}
                                </div>
                                <!-- /Topic Content -->

                                <!-- Topic Likes and Comments Count -->
                                <div fxLayout="row" class="likes-replies">
                                    <!-- Topic Likes and Comments Count -->
                                    <div fxLayout="column">
                                        <div fxLayout="row">
                                            <div
                                                fxLayout="column"
                                                class="like-count-icon"
                                            >
                                                <div
                                                    fxLayout="row"
                                                    (click)="
                                                        toggleLikeComment(
                                                            this.comment
                                                        )
                                                    "
                                                >
                                                    <vsc-icon
                                                        [iconStyle]="
                                                            getIconStyle(
                                                                this.comment
                                                            )
                                                        "
                                                        >heart</vsc-icon
                                                    >
                                                    <p class="like-count">
                                                        {{
                                                            this.comment
                                                                .likesCount
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /Topic Likes and Comments Count -->

                                    <!-- Topic Reply Button -->
                                    <div
                                        fxLayout="column"
                                        fxFlex="50"
                                        fxLayoutAlign="space-between end"
                                        class="reply-btn"
                                    >
                                        <button
                                            mat-flat-button
                                            color="primary"
                                            class="add-reply"
                                            (click)="
                                                toggleReplyTextarea(
                                                    this.comment.id
                                                )
                                            "
                                        >
                                            {{ 'Reply' | transloco }}
                                        </button>
                                    </div>
                                    <!-- /Topic Reply Button -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="commentIdForReply === this.comment.id"
                    fxFlex="row"
                    fxLayoutAlign="space-between end"
                    class="justify-reply-end"
                >
                    <!-- Add new reply -->
                    <form
                        fxLayout="row"
                        fxLayoutAlign="space-between end"
                        fxFlex="90"
                        class="add-new-comment"
                        [formGroup]="replyFormGroup"
                    >
                        <div fxLayout="column" fxFlex="100">
                            <vsc-input
                                type="area"
                                formControlName="content"
                                [showLabel]="false"
                                class="comment-textarea"
                                textAreaRows="10"
                                ariaLabel="Add reply"
                                label="Add reply"
                            ></vsc-input>
                            <div
                                fxFlex="100"
                                fxLayoutAlign="space-between end"
                                class="add-new-comment-btn-wrapper"
                            >
                                <button
                                    mat-flat-button
                                    color="primary"
                                    class="add-new-comment-btn"
                                    (click)="
                                        addReply(this.topic.id, this.comment.id)
                                    "
                                >
                                    {{ 'Add reply' }}
                                </button>
                            </div>
                        </div>
                    </form>
                    <!-- /Add new reply -->
                </div>
            </ng-container>
        </ng-container>
        <!-- /Topic Comments and Replies -->

        <!-- Add new comment -->
        <form
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="add-new-comment"
            [formGroup]="formGroup"
        >
            <div fxLayout="column" fxFlex="100">
                <vsc-input
                    type="area"
                    formControlName="content"
                    [showLabel]="false"
                    class="comment-textarea"
                    textAreaRows="10"
                    ariaLabel="Add comment"
                    label="Add comment"
                    #addCommentArea
                ></vsc-input>
                <div
                    fxFlex="100"
                    fxLayoutAlign="space-between end"
                    class="add-new-comment-btn-wrapper"
                >
                    <button
                        mat-flat-button
                        color="primary"
                        class="add-new-comment-btn"
                        (click)="addComment(this.topic.id)"
                    >
                        {{ 'Add comment' }}
                    </button>
                </div>
            </div>
        </form>
        <!-- /Add new comment -->
    </div>
</ng-container>
