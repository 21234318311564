// comment-update.service.ts
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CommentUpdateService {
    private commentAddedSource: Subject<void> = new Subject<void>();
    private replyAddedSource: Subject<void> = new Subject<void>();

    // eslint-disable-next-line @typescript-eslint/member-ordering
    commentAdded$: Observable<void> = this.commentAddedSource.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    replyAdded$: Observable<void> = this.commentAddedSource.asObservable();

    notifyCommentAdded(): void {
        this.commentAddedSource.next();
    }

    notifyReplyAdded(): void {
        this.replyAddedSource.next();
    }
}
