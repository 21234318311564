import {
    Component,
    OnInit,
    HostListener,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TranslocoService } from '@ngneat/transloco';

import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { OrganizationSettings } from '@app/core/auth/organization-settings';
import { OrganizationSettingsService } from '@app/core/auth/organization-settings.service';
import { AuthService } from '@core/auth/auth.service';
import { Permissions } from '@core/auth/permissions';
import { PermissionService } from '@core/auth/permissions.service';

import { PagePaths } from '@shared/constants/page-paths';
import { CookieHelperService } from '@shared/cookies/services/cookie-helper.service';
import { distinctEmptyArray } from '@shared/operators/rxjs-operators';
import { SideMenuItem } from '@shared/side-menu';
import { SideMenuState } from '@shared/side-menu/side-menu-state';

import { OrganizationService } from './organizations/services/organization.service';
import { ReportService } from './reports/services/report.service';
import { IReport } from './reports/state/report';
import { ReportsQuery } from './reports/state/reports.query';

@Component({
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    selector: 'vsc-admin',
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminComponent implements OnInit, OnDestroy {
    breadcrumb: string = '';
    Permissions: any = Permissions;
    sideMenuState: SideMenuState;
    organizationLogoUrl: string;

    headerBackgroundColor$ = this.organizationSettingsService.selectSetting(
        OrganizationSettings.HeaderBackgroundColor
    );
    headerColor$ = this.organizationSettingsService.selectSetting(
        OrganizationSettings.HeaderTextColor
    );
    firstName$: Observable<string> = this.authService.firstName$;

    private subscriptions: Subscription[] = [];
    private availableReports$: Observable<IReport[]> = this.reportsQuery
        .selectAll()
        .pipe(distinctEmptyArray(), take(1));

    breadcrumbData$: Observable<any> =
        this.routerQuery.selectData('breadcrumb');
    menuItems: Array<SideMenuItem> = [
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Home'),
            iconName: 'house',
            route: 'admin/home',
            visible: true,
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Users'),
            iconName: 'user',
            children: [
                new SideMenuItem({
                    displayName:
                        this.translocoService.selectTranslate('Overview'),
                    iconName: 'user-group',
                    route: 'admin/users',
                    visible: this.permissionsService.hasPermission([
                        Permissions.Users.View,
                        Permissions.Users.Create,
                        Permissions.Users.Delete,
                        Permissions.Users.Edit,
                    ]),
                }),
                new SideMenuItem({
                    displayName:
                        this.translocoService.selectTranslate('Invites'),
                    iconName: 'link-simple',
                    route: 'admin/invites',
                    visible: this.permissionsService.hasPermission([
                        Permissions.Invites.View,
                    ]),
                }),
            ],
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Groups'),
            iconName: 'users',
            route: 'admin/groups',
            visible: this.permissionsService.hasPermission([
                Permissions.Groups.View,
                Permissions.Groups.Edit,
                Permissions.Groups.Create,
                Permissions.Groups.Delete,
            ]),
        }),
        /* new SideMenuItem({
            displayName: this.translocoService.selectTranslate(
                'Master Schedule'
            ),
            iconName: 'today',
            route: 'admin/schedule',
            visible: true,
        }), */
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Programs'),
            iconName: 'book-open',
            route: 'admin/classes',
            visible: this.permissionsService.hasPermission([
                Permissions.Classes.View,
                Permissions.Classes.Create,
                Permissions.Classes.Edit,
                Permissions.Classes.Delete,
            ]),
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Bookmarks'),
            iconName: 'bookmark',
            route: 'admin/bookmarks',
            visible: this.permissionsService.hasPermission([
                Permissions.Internet.View,
                Permissions.Internet.Create,
                Permissions.Internet.Edit,
                Permissions.Internet.Delete,
            ]),
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('News Feeds'),
            iconName: 'newspaper',
            route: 'admin/news-feeds',
            visible: this.permissionsService.hasPermission([
                Permissions.NewsFeeds.View,
                Permissions.NewsFeeds.Create,
                Permissions.NewsFeeds.Edit,
                Permissions.NewsFeeds.Delete,
            ]),
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Announcements'),
            iconName: 'message-exclamation',
            route: 'admin/announcements',
            visible: this.permissionsService.hasPermission([
                Permissions.Announcements.View,
                Permissions.Announcements.Create,
                Permissions.Announcements.Edit,
                Permissions.Announcements.Delete,
            ]),
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Promotions'),
            iconName: 'tower-broadcast',
            route: 'admin/promotions',
            visible: this.permissionsService.hasPermission([
                Permissions.Promotions.View,
                Permissions.Promotions.Create,
                Permissions.Promotions.Edit,
                Permissions.Promotions.Delete,
            ]),
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Alerts'),
            iconName: 'bullhorn',
            route: 'admin/alerts',
            visible: this.permissionsService.hasPermission([
                Permissions.Alerts.View,
                Permissions.Alerts.Create,
                Permissions.Alerts.Edit,
                Permissions.Alerts.Delete,
            ]),
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Forum'),
            iconName: 'message-pen',
            children: [
                new SideMenuItem({
                    displayName:
                        this.translocoService.selectTranslate('Overview'),
                    iconName: 'message-pen',
                    route: 'admin/forum',
                    visible: this.permissionsService.hasPermission([
                        Permissions.Forum.View,
                        Permissions.Forum.Create,
                        Permissions.Forum.Edit,
                        Permissions.Forum.Delete,
                        Permissions.Forum.Close,
                        Permissions.Forum.SuspendUser,
                    ]),
                }),
                new SideMenuItem({
                    displayName:
                        this.translocoService.selectTranslate(
                            'Forum categories'
                        ),
                    iconName: 'layer-plus',
                    route: 'admin/forum-categories',
                    visible: this.permissionsService.hasPermission([
                        Permissions.Forum.View,
                        Permissions.Forum.Create,
                        Permissions.Forum.Edit,
                        Permissions.Forum.Delete,
                        Permissions.Forum.Close,
                        Permissions.Forum.SuspendUser,
                    ]),
                }),
            ],
            visible: this.permissionsService.hasPermission([
                Permissions.Forum.View,
            ]),
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Reports'),
            iconName: 'chart-mixed',
            children: this.availableReports$.pipe(
                map((reports: IReport[]) =>
                    reports.map(
                        (report: IReport) =>
                            new SideMenuItem({
                                displayName:
                                    this.translocoService.selectTranslate(
                                        report.name
                                    ),
                                iconName: 'analytics',
                                route: `${PagePaths.Admin.Reports.trimLeadingSlash()}/${
                                    report.id
                                }`,
                            })
                    )
                )
            ),
            visible: this.permissionsService.hasPermission(
                Permissions.Reports.View
            ),
        }),
        new SideMenuItem({
            displayName: this.translocoService.selectTranslate('Super Admin'),
            iconName: 'shield-keyhole',
            children: [
                new SideMenuItem({
                    displayName:
                        this.translocoService.selectTranslate('Organizations'),
                    iconName: 'building-columns',
                    route: 'admin/organizations',
                    visible: this.permissionsService.hasPermission([
                        Permissions.Organizations.View,
                        Permissions.Organizations.Create,
                        Permissions.Organizations.Edit,
                    ]),
                }),
                new SideMenuItem({
                    displayName:
                        this.translocoService.selectTranslate('Videos'),
                    iconName: 'play',
                    route: 'admin/videos',
                    visible: this.permissionsService.hasPermission([
                        Permissions.Videos.Edit,
                        Permissions.Videos.Create,
                    ]),
                }),
                new SideMenuItem({
                    displayName: this.translocoService.selectTranslate('Roles'),
                    iconName: 'user-shield',
                    route: 'admin/roles',
                    visible: this.permissionsService.hasPermission([
                        Permissions.Roles.View,
                        Permissions.Roles.Edit,
                        Permissions.Roles.Create,
                        Permissions.Roles.Delete,
                    ]),
                }),
            ],
            visible: this.permissionsService.hasPermission([
                Permissions.Administration.View,
            ]),
        }),
    ];

    constructor(
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private cookieConsentService: NgcCookieConsentService,
        private cookieHelperService: CookieHelperService,
        private permissionsService: PermissionService,
        private reportService: ReportService,
        private reportsQuery: ReportsQuery,
        private router: Router,
        private routerQuery: RouterQuery,
        private translocoService: TranslocoService,
        public activatedRoute: ActivatedRoute,
        private organizationSettingsService: OrganizationSettingsService,
        organizationService: OrganizationService
    ) {
        this.subscriptions.push(
            organizationService.organizationLogoUrl$.subscribe(
                (organizationLogoUrl) =>
                    (this.organizationLogoUrl = organizationLogoUrl)
            )
        );
    }

    ngOnInit(): void {
        this.subscriptions = this.subscriptions.concat([
            this.reportService.fetchAllIfEmpty()?.subscribe(),

            this.translocoService.langChanges$.subscribe(() => {
                this.changeDetectorRef.detectChanges();
                const config =
                    this.cookieHelperService.configureCookieConsent();
                this.cookieConsentService.destroy();
                this.cookieConsentService.init(config);
            }),
            this.activatedRoute.url.pipe(take(1)).subscribe(() => {
                this.breadcrumb =
                    this.activatedRoute.snapshot.firstChild.data.breadcrumbs;
            }),
        ]);

        this.cookieHelperService.initializeCookies();
        this.sideMenuState = this.determineSideMenuState();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
    }

    @HostListener('window:orientationchange')
    onOrientationChange(): void {
        setTimeout(() => {
            this.sideMenuState = this.determineSideMenuState();
        }, 200);
    }

    logout(): void {
        this.authService.logout();
    }

    navigateToVSC(): void {
        this.router.navigate([`/home`]);
    }

    determineSideMenuState(): SideMenuState {
        return window.innerWidth <= 969 ? SideMenuState.In : SideMenuState.Out;
    }

    navigateToMyProfile(): void {
        this.router.navigate([PagePaths.Public.Profile], {
            relativeTo: this.activatedRoute,
        });
    }
}
