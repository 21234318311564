import { Injectable } from '@angular/core';

import { OneSignal } from 'onesignal-ngx';
import { Observable, tap, take } from 'rxjs';

import { AuthService } from '@app/core/auth/auth.service';
import { NotificationsSettingsService } from '@app/core/services/notifications-settings.service';
import { environment } from '@environment/environment';

import { NotificationsDataService } from './notifications.dataservice';

@Injectable({
    providedIn: 'root',
})
export class OneSignalHelperService {
    constructor(
        private authService: AuthService,
        private oneSignal: OneSignal,
        private notificationsDataService: NotificationsDataService,
        private notificationsSettingsService: NotificationsSettingsService
    ) {}
    public initOneSignal(isPushNotificationEnabled: boolean): void {
        if (environment.production) {
            this.oneSignal.init({
                appId: environment.oneSignalKey,
                path: './src/OneSignalSDKWorker.js',
            });
        }

        let notificationsPromptStatus: NotificationPermission;
        // eslint-disable-next-line @typescript-eslint/typedef
        let shouldShowPrompt = false;
        if (isPushNotificationEnabled) {
            this.oneSignal
                .getNotificationPermission()
                .then(function (status: NotificationPermission) {
                    notificationsPromptStatus = status;
                })
                .then(function () {
                    switch (notificationsPromptStatus) {
                        case 'default':
                            // User does have a push notifications enabled and the native browser prompt was never shown;
                            // We should show native browser prompt and add user to OneSignal with active notifications subscription (opt-in)
                            shouldShowPrompt = true;
                            break;
                        case 'denied':
                            // User does have a push notifications enabled, but denied the native browser prompt;
                            // We don't have to do anything here, denial means the user doesn't want to reveive notifications and we don't have to add them to OneSignal
                            break;
                        case 'granted':
                            // User does have a push notifications enabled and already allowed the native browser prompt;
                            // If this is the change made by admin, user might have the "opt-out" subscription;
                            // We should set the OneSignal subscription to "opt-in"
                            this.setOneSignalSubscription(true);
                            break;
                        default:
                            // do nothing
                            break;
                    }
                })
                .finally(() => {
                    if (shouldShowPrompt) {
                        this.oneSignal.showNativePrompt();
                        this.oneSignal.setSubscription(true);
                        this.oneSignal.setExternalUserId(
                            this.authService.userId
                        );
                    }
                });
        }
    }

    // This method subscribes/unsubscribes user from OneSignal
    public setOneSignalSubscription(subscriptionStatus: boolean): void {
        this.oneSignal.showNativePrompt();
        this.oneSignal.setSubscription(subscriptionStatus);
        this.oneSignal.setExternalUserId(this.authService.userId);
    }

    public updateOneSignalSubscription(subscriptionStatus: boolean): void {
        this.oneSignal.setSubscription(subscriptionStatus);
        this.oneSignal.setExternalUserId(this.authService.userId);
    }

    public updatePlayerLanguage(lang: string): void {
        this.oneSignal.getUserId().then((userId: string) => {
            if (userId !== null) {
                this.notificationsDataService
                    .updatePlayerLanguage(userId, lang)
                    .pipe(
                        take(1),
                        tap((status: boolean) => {
                            return status;
                        })
                    )
                    .subscribe();
            }
        });
    }
}
