import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { map, debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'vsc-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit, OnChanges {
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {
        this.type = this.router.url?.startsWith('/admin') ? 'admin' : 'public';
    }

    @Output() keyUp: EventEmitter<string> = new EventEmitter();
    @Input() placeholder: string = 'Search...';
    @Input() maxLength: number = Number.MAX_SAFE_INTEGER;
    @Input() defaultValue: string = '';

    type: 'public' | 'admin';

    value: string;
    tempId: string;

    inputValue = new Subject<string>();
    trigger = this.inputValue.pipe(debounceTime(300), distinctUntilChanged());

    ngOnInit(): void {
        this.tempId = String.generateRandomCharacters(15);
        this.value = this.defaultValue;

        this.trigger
            .pipe(
                untilDestroyed(this),
                map((currentValue) => {
                    this.keyUp.emit(currentValue);
                })
            )
            .subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.defaultValue?.previousValue !=
            changes.defaultValue?.currentValue
        ) {
            this.changeValue(changes.defaultValue.currentValue);
        }
    }

    __valueChanged(value: string): void {
        this.value = value;
        this.inputValue.next(value);
    }

    changeValue(value: string): void {
        this.value = value;
        this.changeDetectorRef.detectChanges();
    }

    onChange(value) {
        if (value !== this.value) {
            this.__valueChanged(value);
        }
    }
}
