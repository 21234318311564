import { Injectable } from '@angular/core';

import { QueryEntity, combineQueries } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import * as moment from 'moment';
import { Observable, map } from 'rxjs';

import { UserService } from '@app/admin/admin/users/services/user.service';
import { AuthService } from '@app/core/auth/auth.service';

import { Constants } from '@shared/constants';
import { Lookup } from '@shared/state/lookup/lookup';
import { LookupService } from '@shared/state/lookup/lookup.service';
import { Lookups } from '@shared/state/lookup/lookups';

import { Topic } from './topic';
import { TopicsState, TopicsStore } from './topics.store';

@Injectable({
    providedIn: 'root',
})
export class TopicsQuery extends QueryEntity<TopicsState, Topic> {
    organizations$: Observable<Array<Lookup>> = this.lookupService.selectLookup(
        Lookups.ForumContentExchangeEnabledOrganizations
    );

    forumCategories$: Observable<Array<Lookup>> =
        this.lookupService.selectLookup(Lookups.ForumCategories);

    selectOrganizationsWithoutLoggedOne$: Observable<Lookup[]> =
        this.organizations$.pipe(
            map((organizations: Lookup[]) => {
                const tenantId: string = this.authService.tenantId;
                return organizations.filter(
                    (organization: Lookup) => organization.id !== tenantId
                );
            })
        );

    selectForumCategories$: Observable<Lookup[]> = this.forumCategories$;

    selectBackUrl$: Observable<string> = this.select(
        (topicsState: TopicsState) => topicsState.backUrl
    );

    selectIsEditMode$: Observable<boolean> = this.routerQuery
        .selectParams('id')
        .pipe(map((value: any) => !!value));

    selectActiveTopic$: Observable<Topic> = combineQueries([
        this.routerQuery.selectParams(),
        this.selectAll(),
    ]).pipe(
        map((combine: [any, Topic[]]) => {
            const topicItem: Topic = combine[1].find(
                (x: Topic) => x.id === combine[0]['id']
            );

            if (topicItem) {
                return {
                    ...topicItem,
                    createdAt: topicItem.createdAt
                        ? moment(topicItem.createdAt)
                        : null,
                };
            }

            return topicItem;
        })
    );

    getUserSuspendedStatus$: Observable<boolean> =
        this.usersService.getUserSuspendedStatus(this.authService.userId);

    getUserSuspendedStatus: Observable<boolean> =
        this.getUserSuspendedStatus$.pipe(map((x: boolean) => x));

    getIsUserInRoles$: Observable<boolean> = this.usersService.getIsUserInRoles(
        this.authService.userId
    );

    getUserForumEnabled$: Observable<boolean> =
        this.usersService.getUserForumEnabled();

    getIsUserInRoles: Observable<boolean> = this.getIsUserInRoles$.pipe(
        map((x: boolean) => x)
    );

    constructor(
        protected store: TopicsStore,
        private lookupService: LookupService,
        private authService: AuthService,
        private routerQuery: RouterQuery,
        private usersService: UserService
    ) {
        super(store);
    }

    getIsEditMode(): boolean {
        return this.routerQuery.getParams('id') !== undefined;
    }

    getBackUrl(): string {
        return this.getValue()?.backUrl ?? Constants.FORUM_DEFAULT_BACK_URL;
    }

    getActiveTopic(): Topic {
        return this.getEntity(this.routerQuery.getParams('id'));
    }

    getEditingId(): string {
        return this.routerQuery.getParams('id');
    }
}
