import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { FormsModule } from '@shared/forms/forms.module';
import { MatSpinnerDirectiveModule } from '@shared/mat-spinner/mat-spinner.module';
import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { ForumRoutingModule } from './forum-routing.module';
import { ForumComponent } from './forum.component';
import { TopicCommentsComponent } from './topic-comments/topic-comments.component';
import { TopicListItemComponent } from './topic-list-item/topic-list-item.component';
import { TopicManageComponent } from './topic-manage/topic-manage.component';

@NgModule({
    declarations: [
        ForumComponent,
        TopicListItemComponent,
        TopicManageComponent,
        TopicCommentsComponent,
    ],
    imports: [
        CommonModule,
        ForumRoutingModule,
        MaterialModule,
        SharedModule,
        FormsModule,
        InfiniteScrollModule,
        MatSpinnerDirectiveModule,
    ],
})
export class ForumModule {}
