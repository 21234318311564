export enum BuiltInRoleType {
    All = 0,
    Participant,
    Facilitator,
    Caregiver,
    GroupAdmin,
    OrganizationAdmin,
    SuperAdmin,
    Moderator = 10,
}
