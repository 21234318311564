import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
    Observable,
    Subscription,
    filter,
    finalize,
    switchMap,
    take,
    tap,
} from 'rxjs';

import { AuthService } from '@app/core/auth/auth.service';

import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { Constants } from '@shared/constants';

import { CommentUpdateService } from '../services/comment-update.service';
import { ForumService } from '../services/forum.service';
import { Like } from '../state/topic-state/like';
import { Topic } from '../state/topic-state/topic';
import { TopicsQuery } from '../state/topic-state/topics.query';

@Component({
    selector: 'vsc-topic-comments',
    templateUrl: './topic-comments.component.html',
    styleUrls: ['./topic-comments.component.scss'],
})
export class TopicCommentsComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @ViewChild('addCommentArea', { static: false }) addCommentArea: any;
    @ViewChild('scrollTarget', { static: true }) scrollTarget: any;

    topic: Topic;
    backUrl$: Observable<string> = this.topicsQuery.selectBackUrl$;
    colorsArray: Array<string> = Constants.AVATAR_COLORS;
    formGroup: FormGroup;
    isAddCommentAreaAvailable: boolean = false;
    commentIdForReply: any | null = null;
    replyFormGroup: FormGroup;

    topic$: Observable<Topic> = this.topicsQuery.selectActiveTopic$.pipe(
        filter(Boolean),
        take(1),
        tap((topicEntity: Topic) => {
            if (topicEntity && !this.topic) {
                this.topic = topicEntity;
            }
        })
    );

    private subscriptions: Subscription[] = [];

    constructor(
        private topicsQuery: TopicsQuery,
        private forumService: ForumService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private commentUpdateService: CommentUpdateService,
        private dialog: MatDialog
    ) {}

    get isLiked(): boolean {
        const like: Like = this.topic.likeDtos.find(
            (x: Like) => x.userId === this.authService.userId
        );

        return like !== null && like !== undefined;
    }

    get canEdit(): boolean {
        return this.topic.createdById === this.authService.userId;
    }

    ngAfterViewInit(): void {
        this.isAddCommentAreaAvailable = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.forumService.fetchTopicIfNotPresent().subscribe()
        );

        this.formGroup = this.formBuilder.group({
            content: [null, [Validators.maxLength(1000)]],
            forumTopicId: null,
            userId: this.authService.userId,
            parentCommentId: null,
        });

        this.replyFormGroup = this.formBuilder.group({
            content: [null, [Validators.maxLength(1000)]],
            forumTopicId: null,
            userId: this.authService.userId,
            parentCommentId: null,
        });

        // Subscribe to the commentAdded$ observable
        this.subscriptions.push(
            this.commentUpdateService.commentAdded$.subscribe(() => {
                this.forumService
                    .fetchTopicIfNotPresent()
                    .subscribe(() => window.location.reload());
            })
        );
        this.subscriptions.push(
            this.commentUpdateService.replyAdded$.subscribe(() => {
                this.forumService
                    .fetchTopicIfNotPresent()
                    .subscribe(() => window.location.reload());
            })
        );
    }

    getRandomColor(): any {
        // eslint-disable-next-line no-bitwise
        // return this.colorsArray[(Math.random() * this.colorsArray.length) | 0];
        return '#a0e5c9';
    }

    scrollToCommentArea(): void {
        if (this.isAddCommentAreaAvailable) {
            const addCommentAreaElement: HTMLElement = this.addCommentArea
                .elementRef.nativeElement as HTMLElement;

            addCommentAreaElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    toggleReplyTextarea(commentId: string): void {
        if (this.commentIdForReply === commentId) {
            this.commentIdForReply = null;
        } else {
            this.commentIdForReply = commentId;
        }
    }

    addReply(topicId: string, parentCommentId: string): void {
        if (this.replyFormGroup.valid && !this.replyFormGroup.pristine) {
            this.upsertComment(topicId, parentCommentId).subscribe();
        } else {
            this.replyFormGroup.markAllAsTouched();
        }
    }

    addComment(topicId: string, parentCommentId: string = null): void {
        if (this.formGroup.valid && !this.formGroup.pristine) {
            this.upsertComment(topicId, parentCommentId).subscribe();
        } else {
            this.formGroup.markAllAsTouched();
        }
    }

    upsertComment(
        topicId: string,
        parentCommentId: string = null
    ): Observable<Topic> {
        this.formGroup.get('forumTopicId').setValue(topicId);

        if (parentCommentId !== null && parentCommentId !== undefined) {
            this.formGroup.get('parentCommentId').setValue(parentCommentId);
            this.formGroup
                .get('content')
                .setValue(this.replyFormGroup.get('content').value);
        }

        const formValue: any = { ...this.formGroup.value };

        return this.forumService.upsertComment(formValue).pipe(
            take(1),
            finalize(() => {
                this.formGroup.markAsPristine();
            })
        );
    }

    scrollToComment(parentCommentId: string): void {
        const elementToScroll: HTMLElement =
            document.getElementById(parentCommentId);

        if (elementToScroll) {
            elementToScroll.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    }

    toggleLikeTopic(item: Topic, $event: any): void {
        $event.stopPropagation();
        this.forumService
            .toggleLikeTopic(item.id, !item.isLiked)
            .pipe(take(1))
            .subscribe(() => {
                window.location.reload();
            });
    }

    toggleLikeComment(item: any): void {
        this.forumService
            .toggleLikeComment(item.id, !item.isLiked)
            .pipe(
                take(1),
                tap((response: any) => {
                    this.getIconStyle(response);
                    window.location.reload();
                })
            )
            .subscribe();
    }

    getIconStyle(comment: any): string {
        return comment.isLiked ? 'solid' : 'regular';
    }

    flagComment(id: string, $event: any): void {
        $event.stopPropagation();
        this.dialog
            .open(ConfirmDialogComponent, {
                width: '500px',
                data: {
                    title: 'Flag Topic',
                    message: 'Are you sure you want to flag this comment?',
                    yes: 'Yes',
                    noButtonVisible: true,
                    no: 'No',
                },
            })
            .beforeClosed()
            .pipe(
                take(1),
                switchMap((isConfirmed: boolean) => {
                    if (isConfirmed) {
                        return this.forumService.flagComment(id).pipe(take(1));
                    }
                })
            )
            .subscribe();
    }
}
