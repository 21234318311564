<div fxLayoutGap="13px" class="forum-container" [vscMatSpinner]="isLoading$">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div fxLayout="column" fxFlex="77">
                    <div
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                        class="create-topic-wrapper mt-13"
                    >
                        <div
                            fxFlex="50"
                            fxLayout="column"
                            fxLayoutAlign="space-between start"
                            class="forum-title-container"
                        >
                            <h1 class="mat-h1 no-margins">
                                {{ 'Forum' | transloco }}
                            </h1>
                        </div>
                        <div
                            fxFlex="50"
                            fxLayout="column"
                            fxLayoutAlign="space-between end"
                        >
                            <button
                                mat-flat-button
                                color="primary"
                                class="create-topic"
                                (click)="createNewTopic()"
                                [disabled]="userSuspendedStatus$ | async"
                            >
                                {{ 'Start discussion' | transloco }}
                            </button>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="23" class="sticky-filters"></div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
                <!-- Forum Topics -->
                <div fxLayout="column" fxFlex="77" class="all-topics-container">
                    <ng-container *ngIf="topics$ | async as topics">
                        <ng-container *ngIf="topics?.length; else noItems">
                            <div class="list-view">
                                <ng-container
                                    *ngFor="
                                        let topic of topics;
                                        trackBy: trackByFn
                                    "
                                >
                                    <vsc-topic-list-item
                                        [item]="topic"
                                    ></vsc-topic-list-item>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #noItems>
                        <div
                            class="no-items"
                            fxLayoutAlign="center center"
                            fxLayout="column"
                        >
                            <ng-container
                                *ngIf="(isLoading$ | async) === false"
                            >
                                <h3>
                                    {{ 'There are no topics' | transloco }}
                                </h3>
                            </ng-container>
                        </div>
                    </ng-template>
                </div>
                <!-- /Forum Topics -->

                <!-- Forum Filters -->
                <div fxLayout="column" fxFlex="23" class="sticky-filters">
                    <vsc-search-input
                        #txtSearch
                        placeholder="{{ 'Search topics' | transloco }}"
                        (keyUp)="onKeyUp($event)"
                    >
                    </vsc-search-input>

                    <br />
                </div>
                <!-- /Forum Filters -->
            </div>
        </div>
    </div>
</div>
