import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ID } from '@datorama/akita';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { PagedEntities } from '@shared/models/paged-entities';
import { QueryParamsUtils } from '@shared/utils/query-params.utils';

import { Class } from '../../classes/class-state/class';
import { Language } from '../../languages/state/languages';
import { User } from '../../users/state/user';
import { Group } from '../state/group';
import { GroupSettings } from '../state/group-settings';
import { GroupUserInfo } from '../state/group-user-info';
import { GroupClassesQueryParams } from './query-params/group-classes.queryparams';
import { GroupUsersQueryParams } from './query-params/group-users.queryparams';
import { GroupsQueryParams } from './query-params/groups.queryparams';

@Injectable({ providedIn: 'root' })
export class GroupDataservice {
    constructor(private httpClient: HttpClient) {}

    getGroups(
        queryParams?: GroupsQueryParams
    ): Observable<PagedEntities<Group>> {
        const params = new HttpParams({
            fromObject: QueryParamsUtils.convertParametersToString(queryParams),
        });
        return this.httpClient.get<PagedEntities<Group>>(
            environment.apiUrl + '/vsc/groups',
            {
                params,
            }
        );
    }
    getUsersOfGroup(
        queryParams: GroupUsersQueryParams
    ): Observable<PagedEntities<User>> {
        const params = new HttpParams({
            fromObject: QueryParamsUtils.convertParametersToString(queryParams),
        });

        return this.httpClient.get<PagedEntities<User>>(
            environment.apiUrl + `/vsc/groups/${queryParams.groupId}/users`,
            {
                params,
            }
        );
    }
    getClassesOfGroup(
        queryParams: GroupClassesQueryParams
    ): Observable<PagedEntities<Class>> {
        const params = new HttpParams({
            fromObject: QueryParamsUtils.convertParametersToString(queryParams),
        });

        return this.httpClient.get<PagedEntities<Class>>(
            environment.apiUrl + `/vsc/groups/${queryParams.groupId}/classes`,
            {
                params,
            }
        );
    }

    getGroupsAndUserInfo(
        queryParams?: GroupsQueryParams
    ): Observable<PagedEntities<GroupUserInfo>> {
        const params = new HttpParams({
            fromObject: QueryParamsUtils.convertParametersToString(queryParams),
        });
        return this.httpClient.get<PagedEntities<GroupUserInfo>>(
            environment.apiUrl + '/vsc/groups/user-info',
            {
                params,
            }
        );
    }

    getById(id: string): Observable<Group> {
        return this.httpClient.get<Group>(
            environment.apiUrl + `/vsc/groups/${id}`
        );
    }

    exists(name: string): Observable<boolean> {
        const encodedName = encodeURIComponent(name);
        return this.httpClient.get<boolean>(
            environment.apiUrl + `/vsc/groups/exists/${encodedName}`
        );
    }

    post(group: any): Observable<any> {
        return this.httpClient.post<any>(
            environment.apiUrl + `/vsc/groups`,
            group
        );
    }

    put(group: any): Observable<any> {
        return this.httpClient.put<any>(
            environment.apiUrl + `/vsc/groups`,
            group
        );
    }

    updateGroupSettings(
        groupId: ID,
        groupSettings: GroupSettings
    ): Observable<void> {
        const uri = `${environment.apiUrl}/vsc/groups/${groupId}`;

        return this.httpClient.patch<any>(uri, groupSettings);
    }

    getGroupLanguages(): Observable<Array<Language>> {
        const uri = `${environment.apiUrl}/vsc/groups/user-groups-languages`;

        return this.httpClient.get<Array<Language>>(uri);
    }
}
