<div fxLayout="column" class="topic-manage">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="title">
        <div fxLayout="row" fxLayoutAlign="start center">
            <vsc-back-button [url]="backUrl$ | async"></vsc-back-button>
            <div class="divider"></div>
            <h1 class="no-margins topic-title">
                {{
                    isEditMode
                        ? ('Editing topic' | transloco) + ':'
                        : ('Create a topic' | transloco)
                }}
                {{ isEditMode ? topic?.title : '' }}
            </h1>
        </div>
    </div>
    <div fxLayout="row" fxFlex="100" class="topic-management-container">
        <mat-card [vscMatSpinner]="isLoading$" fxLayout="row" fxFlex="100">
            <form
                fxLayout="column"
                fxLayoutAlign="space-evenly strech"
                fxFlex="70"
                fxLayoutGap="10px"
                [formGroup]="formGroup"
                [vscConnectForm]="topic$ | async"
                class="manage-topic-form"
            >
                <mat-card-content>
                    <div fxLayout="column">
                        <vsc-input
                            class="title-input"
                            fxFlex="100"
                            formControlName="title"
                            label="Title"
                            hint="Set the title for your topic."
                            hintPosition="between"
                        >
                        </vsc-input>

                        <div
                            fxFlex
                            fxLayout="row"
                            fxLayoutGap="10px"
                            class="content"
                        >
                            <vsc-input
                                fxFlex="100"
                                type="area"
                                placeholder="Click to add content..."
                                formControlName="content"
                                label="Content"
                                hint="Add your topic content."
                                hintPosition="between"
                                [textAreaRows]="10"
                            ></vsc-input>
                        </div>

                        <vsc-select-search
                            fxFlex="100"
                            label="Category"
                            placeholder="Category"
                            formControlName="forumCategoryId"
                            [options]="forumCategories$ | async"
                            [searchable]="true"
                            searchPlaceholder="Search categories.."
                            [isSelectAllEnabled]="false"
                            [isTranslatable]="true"
                        >
                        </vsc-select-search>

                        <div fxFlex fxLayout="row" fxLayoutGap="10px">
                            <mat-checkbox
                                formControlName="isAnonymous"
                                [checked]="
                                    formGroup.get('isAnonymous').value === true
                                "
                                >{{ 'Make me anonymous' | transloco }}
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-card-content>
                <div
                    class="topic-actions"
                    fxLayout="column"
                    fxLayoutAlign="center start"
                >
                    <mat-card-actions fxFlex fxLayout="row" fxLayoutAlign="end">
                        <button
                            type="button"
                            mat-stroked-button
                            color="primary"
                            class="border mr-16"
                            [routerLink]="backUrl$ | async"
                        >
                            {{ 'Cancel' | transloco }}
                        </button>
                        <button
                            type="submit"
                            mat-flat-button
                            color="primary"
                            (click)="save()"
                            [disabled]="
                                formGroup.pending ||
                                ((isEditMode$ | async) && formGroup.pristine)
                            "
                        >
                            {{
                                ((this.isEditMode$ | async)
                                    ? 'Save changes'
                                    : 'Create a topic'
                                ) | transloco
                            }}
                        </button>
                    </mat-card-actions>
                </div>
            </form>
        </mat-card>
    </div>
</div>
