export enum AppUnit {
    Home,
    Classroom,
    UpcomingClasses,
    PastClasses,
    MyCalendar,
    Chat,
    Internet,
    Games,
    Privacy,
    TOS,
    SupportRequest,
    Users,
    Groups,
    Bookmarks,
    Newsfeeds,
    Announcements,
    Promotions,
    Organizations,
    Roles,
    ClassDetails,
    ClassMaterials,
    ChatRoom,
    News,
    UserSettings,
    Weather,
    Cookies,
    VideoLibrary,
    Notifications,
    Forum,
}
